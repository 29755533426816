
import { defineComponent } from "vue";
import dateFormat from "@/utils/dateFormat";
export default defineComponent({
  name: "Support",
  props: {
    support: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    function formatAvatar(id: string, item: any) {
      let src = "";
      if (!Number(id)) {
        src =
          "https://yingzegbw.oss-cn-beijing.aliyuncs.com/common/logo.svg";
      } else {
        src = item.user.userImg;
      }
      return src
    }
    return {
      dateFormat,
      formatAvatar,
    };
  },
});
